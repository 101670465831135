<template>
  <div class="">
    <div class="lg-up:d-none">
      <mega-card>
        <div class="card-body">
          <mega-select
            class="mb-0"
            v-model="group"
            @select="route"
            :options="groupsOptions"
          />
        </div>
      </mega-card>
    </div>

    <div class="md-down:d-none">
      <router-link
        class="btn d-block text-left card-border mb-3"
        exact
        :class="routeGroup === undefined ? 'btn-primary' : 'bg-white'"
        :to="{ name: 'Models', params: { is_active } }"
      >
        <i class="i-grid-solid float-left opacity-50"></i>
        <span>{{ $t("all_models") }}</span>
      </router-link>

      <div
        class="btn d-block text-left card-border mb-5"
        :class="routeGroup === 0 ? 'btn-primary' : 'bg-white'"
        @click="route(0)"
      >
        <i class="i-close-circle-solid float-left opacity-50"></i>
        <span>{{ $t("wo_group") }}</span>
      </div>

      <span class="form-label mb-3">{{ $t("groups") }}</span>

      <div v-if="round_type">
        <div
          class="btn d-block text-left card-border mb-3"
          v-for="(group, i) in groups"
          :key="i"
          @click="route(group.idt_group)"
          :class="routeGroup === group.idt_group ? 'btn-primary' : 'bg-white'"
        >
          <i
            class="float-left opacity-50"
            :class="group.is_active ? 'i-folder-solid' : 'i-folder'"
          ></i>
          <span class="float-right opacity-50">{{ group["model_count"] }}</span>
          <span>{{ group.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    routeGroup: {
      type: Number
    }
  },
  data() {
    return {
      loading: true,
      group: undefined,
      round_type: undefined,
      groups: []
    };
  },
  computed: {
    groupsOptions() {
      let arr = [
        { value: undefined, name: "All models" },
        { value: 0, name: "Without group" }
      ];

      this.groups.map(item => {
        arr.push({ value: item.idt_group, name: item.name });
      });

      return arr;
    },

    is_active() {
      return this.$route.params.is_active;
    }
  },
  methods: {
    route(idt_group) {
      let query = this.$route.query;
      let params = {
        roundType: this.getType(this.round_type),
        is_active: this.is_active
      };

      if (idt_group !== undefined) params["groupId"] = idt_group;

      this.$router.push({ query, params });
    },

    load(type) {
      this.loading = true;
      this.round_type = type;

      let params = {
        limit: 500,
        idc_round_type: this.round_type,
        offset: 0,
        sn_id: this.$store.getters.selectedSnId
      };

      this.$api.v2
        .get("/groups", { params })
        .then(response => {
          this.groups = [];

          this.groups = response.data.groups || [];
          this.loading = false;
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },

    getType(type) {
      switch (type) {
        case 4:
          return "3d";
          break;
        case 5:
          return "gif";
          break;
        case 10:
          return "mask";
          break;
        case 12:
          return "portal";
          break;
      }
    }
  }
};
</script>
